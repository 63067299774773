@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

body {
  background-color: #000002;
  margin: 0;
  font-family: "Helvetica", "sans-serif", "-apple-system", "BlinkMacSystemFont",
    '"Segoe UI"', "Roboto", '"Apple Color Emoji"', '"Segoe UI Emoji"',
    '"Segoe UI Symbol"', "-apple-system";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Alibaba-PuHuiTi-Heavy";
  src: local("Alibaba-PuHuiTi-Heavy"),
    url(./assets/fonts/Alibaba-PuHuiTi-Heavy.otf) format("otf");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
